<template>
    <div v-if="invoiceData.client_data" class="text-sm">
        <div class="grid grid-cols-12 gap-2 mb-2">
            <div class="col-span-5 p-4 rounded-lg">
                <img v-if="invoiceData.logo_src" :src="invoiceData.logo_src" alt="">
            </div>
            <div class="col-span-7">
                <div class="bg-gray-50 border-gray-200 border p-3 rounded-lg mb-2">
                    {{ invoiceName }}
                </div>
                <div class="grid grid-cols-2 gap-2">
                    <div class="bg-gray-50 border-gray-200 border p-3 rounded-lg grid grid-cols-2 gap-2">
                        <p class="border-r border-gray-800 text-xs font-semibold">
                            {{ $t('economy.invoice_nr') }}
                        </p>
                        <p class="text-xs font-semibold">
                            {{ $t('economy.client_nr') }}
                        </p>
                        <p>{{ invoiceData.custom_nr }}</p>
                        <p>{{ invoiceData.client_data.custom_id }}</p>
                    </div>
                    <div class="bg-gray-50 border-gray-200 border p-3 rounded-lg grid grid-cols-2 gap-2">
                        <p class="border-r border-gray-800 text-xs font-semibold">
                            {{ $t('economy.issue_at') }}
                        </p>
                        <p class="text-xs font-semibold">
                            {{ $t('economy.due_date') }}
                        </p>
                        <p>{{ invoiceData.issue_at }}</p>
                        <p>{{ invoiceData.due_date_at }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-12 gap-2">
            <div class="col-span-5 bg-gray-50 border-gray-200 border p-4 rounded-lg">
                <p class="font-semibold mb-1">
                    {{ invoiceData.user_data.company_name }}
                </p>
                <p>{{ invoiceData.user_data.street }}</p>
                <p>{{ invoiceData.user_data.post_code }} {{ invoiceData.user_data.city }}</p>
                <p>{{ invoiceData.user_data.country }}</p>
            </div>
            <div class="col-span-7 bg-gray-50 border-gray-200 border p-4 rounded-lg">
                <p class="font-semibold mb-1">
                    {{ invoiceData.client_data.name }} {{ invoiceData.client_data.surname }}
                </p>
                <p v-if="invoiceData.client_data.type === 'private'">
                    Personnummer: {{ invoiceData.client_data.person_org_nummer }}
                </p>
                <p v-else>
                    Orgnummer: {{ invoiceData.client_data.person_org_nummer }}
                </p>
                <p>{{ invoiceData.client_data.street }}</p>
                <p>{{ invoiceData.client_data.post_code }} {{ invoiceData.client_data.city }}</p>
                <p>{{ invoiceData.client_data.country }}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        invoiceData: {
            type:    Object,
            default: () => {},
        },
    },

    computed: {
        invoiceName() {
            if (this.invoiceData.type === 'transfer') {
                return this.$t('economy.invoice');
            }
            if (this.invoiceData.type === 'autogiro') {
                return 'Autogiro';
            }
            return '';
        },
    },
};
</script>
